  <template >
  <div>
    <v-fab-transition v-if="buttons.length === 1" >
      <v-btn style="bottom: 50px" :color="buttons[0].color" fab dark bottom right fixed @click="buttons[0].fn(buttons[0].fn_params)">
        <v-icon>{{ buttons[0].icon }}</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-speed-dial
      style="bottom: 50px" 
      v-model="fab"
      :top="false"
      :bottom="true"
      :right="true"
      direction="top"
      transition="slide-y-reverse-transition"
      absolute
      fixed
      v-else
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab 
      >
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-dots-horizontal </v-icon>
        </v-btn>
      </template>
      <v-tooltip left v-for="button in buttons" :key="button.name">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            v-bind="attrs"
            v-on="on"
            :color="button.color"
            @click="button.fn"
          >
            <v-icon>{{ button.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ button.name }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
export default {
  props: {
    buttons: Array,
  },
  data() {
    return {
      fab: false,
    };
  },
};
</script>