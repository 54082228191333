<template>
    <v-text-field
      @input="setSearchTerm"
      append-icon="mdi-magnify"
      label="Buscar"
      single-line
      hide-details
      clearable
      outlined
      dense
    ></v-text-field>
</template>

<script>
export default {
  props: {
      setSearchTerm: Function
  },
};
</script>